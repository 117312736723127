import Swal from 'sweetalert2';
import fetch from 'isomorphic-unfetch';
import LogRocket from 'logrocket';

let recordingURL;

export default function fetchSwal(method, url, data, options = {}, noDefaultOpts) {

  if (typeof window !== 'undefined' && !recordingURL) {
    LogRocket.getSessionURL((sessionURL) => {
      recordingURL = sessionURL;
    });
  }
  const opts = options;
  // formdata/multipart must have no 'Content-Type
  if (noDefaultOpts !== true) {
    opts.headers = {
      'Content-Type': 'application/json',
      'X-LogRocket-URL': recordingURL,
      ...opts.headers,
    };
  }
  if (data && method === 'GET') {
    const queryParams = Object.keys(data)
      .filter(k => data[k] !== undefined && data[k] !== null)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
      .join('&');
    url += '?' + queryParams;
  }
  return fetch(url, {
    ...opts,
    method,
    // eslint-disable-next-line no-nested-ternary
    body: data && method !== 'GET'
      ? opts && opts.headers && opts.headers['Content-Type'] === 'application/json'
        ? JSON.stringify(data)
        : data
      : null,
  })
    .then((response) => {
      if (response.status === 403) {
        return response.json()
          .then(json => ({ ok: false, message: "You've been logged out. Please log in again.", ...json }));
      } else {
        return response.json();
      }
    })
    .then((json) => {
      const isError = (json.ok === false || json.ok === undefined);
      if (opts.noToast !== true && json.noToast!==true) {
        Swal.fire({
          toast: true,
          showConfirmButton: false,
          position: 'bottom-end',
          timer: 3000,
          title: json.message || (isError ? 'An error has occurred.' : 'Success'),
          icon: isError ? 'error' : 'success',
        });
      }
      return json;
    }).catch(err => {
      Swal.fire({
        toast: true,
        showConfirmButton: false,
        position: 'bottom-end',
        timer: 6000,
        title: (err.message && err.message.toLowerCase().indexOf("json") === -1) ? err.message : 'Sorry, something went wrong. Try that again.',
        icon: 'error',
      });
    });
}

fetchSwal.get = fetchSwal.bind(this, 'GET');
fetchSwal.delete = fetchSwal.bind(this, 'DELETE');
fetchSwal.post = fetchSwal.bind(this, 'POST');
fetchSwal.put = fetchSwal.bind(this, 'PUT');
fetchSwal.patch = fetchSwal.bind(this, 'PATCH');
